<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="1">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-img fluid :src="data.cover"></b-img>
                  </div>
                </b-col>
                <b-col class="my-auto" md="9">
                  <h4>
                    <span style="color: #7367f0">name :</span>
                    {{ name }}
                  </h4>
                  <br />
                  <h4>
                    <span style="color: #7367f0">SKU رمز : </span>
                    {{ data.sku }}
                  </h4>
                  <br />
                </b-col>
              </b-row>
            </b-col>
            <!-- tags -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="tags"
                rules="required"
              >
                <b-form-group
                  label="Tags"
                  label-for="tag"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    multiple
                    id="category"
                    v-model="tagSelect"
                    :state="tagSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tagOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required } from '@validations'
export default { 
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      tagSelect: [],
      tagOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      itemData: {
        tags: [],
      },
      data: null,
      name:"",
      required,
    }
  },
  async created() {
    this.id = this.$route.params.id
    await this.getProduct()
    await this.getTags()
    
  },
  methods: {
    async getProduct() {
      await axios
      .get('products/' + this.id)
      .then((result) => {
        this.data = result.data.data
        this.name = this.data.translation[0].name
        if (this.data.tags.length > 0) {
          for (let item in this.data.tags) {
            this.tagSelect.push({
              text: this.data.tags[item].name,
              value: this.data.tags[item].id.toString(),
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    },
    async getTags() {
      await axios
      .get('tags')
      .then((result) => {
        const data = result.data.data
        if (data.length > 0) {
          for (let tg in data) {
            this.tagOptions.push({
              text: data[tg].name,
              value: data[tg].id.toString(),
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.itemData.tags = []
          // eslint-disable-next-line
          for (let ta in this.tagSelect) {
            this.itemData.tags.push(this.tagSelect[ta].value)
          }

          axios
            .post('products/' + this.id + '/tags/sync', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
 
</style>
